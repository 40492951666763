import { StyleSheet } from 'aphrodite/no-important'
import _includes from 'lodash/includes'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Content from '../components/Content'
import Copy from '../components/Copy'
import Headline from '../components/Headline'
import Layout from '../components/layout'
import Stage from '../components/Stage'
import StyledLink from '../components/StyledLink'
import { Helmet } from 'react-helmet'

class blogpost extends Component {
    render() {
        const pageContext = this.props.pageContext,
            content = JSON.parse(pageContext.content.raw).content,
            title = pageContext.title,
            stageImage = pageContext.stageImage

        return (
            <Layout activeLink={this.props['*']}>
                <Helmet
                    title={'Bride Surprise | Unsere Geschichten | ' + title}
                    meta={[
                        {
                            name: 'description',
                            content: title,
                        },
                        { name: 'keywords', content: 'Bride, Braut, Junggesellinnen, Junggesellinnenabschied' },
                    ]}
                >
                    <html lang="de" />
                </Helmet>

                <Content>
                    <Stage image={stageImage} small={true} />

                    <Headline left type="h1" text={title} />

                    {content.map((node, i) => {
                        if (node.nodeType === 'paragraph') {
                            const text = node.content.map(p => {
                                if (p.nodeType === 'hyperlink') {
                                    return (
                                        <StyledLink key={i} linkTarget={p.data.uri}>
                                            {p.content[0].value}
                                        </StyledLink>
                                    )
                                } else {
                                    return p.value
                                }
                            })
                            return <Copy key={i}>{text}</Copy>
                        } else if (_includes(node.nodeType, 'heading')) {
                            return (
                                <Headline
                                    left
                                    key={i}
                                    type={node.nodeType === 'heading-1' ? 'h1' : 'h2'}
                                    text={node.content[0].value}
                                />
                            )
                        }
                    })}
                </Content>
            </Layout>
        )
    }
}

blogpost.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

const styles = StyleSheet.create({})

export default blogpost
